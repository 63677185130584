<template>
  <b-navbar toggleable="sm" type="light" variant="" class="navbar-warapper">
    <!-- <h4 class="ml-lg-5 text-white px-4">MoveMentor</h4> -->
    <div class="pr-4 pl-5">
      <router-link to="/dashboard" class="d-flex flex-column justify-content-center align-items-center">
        <img src="../assets/images/logo.svg" alt="user" class="" width="30"/>
        <span class="logo-text">Nouview</span>
      </router-link>
    </div>
    <b-collapse id="nav-collapse" is-nav class="">
      <!-- -----------------------------------------------------------
          Right aligned nav items
        ----------------------------------------------------------- -->
      <b-navbar-nav class="ml-auto align-items-center pr-0 pr-sm-5">
        <b-nav-item-dropdown right no-caret>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <img v-if="user.user.images.profile" :src="user.user.images.profile" alt="user" class="rounded-circle profile-icon" width="31"/>
            <img v-else src="../assets/images/1.jpg" alt="user" class="rounded-circle profile-icon" width="31"/>
          </template>
          <div data-v-ae7504a4="" class="triangle-bottom-left"></div>
          <b-dropdown-item href="#" class="d-flex align-items-center profile-custom" @click="profile">
            Profile
          </b-dropdown-item>
          <b-dropdown-item href="#" class="d-flex align-items-center" @click="logout">
            Sign out
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <b-nav-form> </b-nav-form>
  </b-navbar>
</template>
<script>
  import {mapState } from 'vuex'
export default {
  name: "NavbarNew",
  data() {
    return {};
  },
  computed:{
    ...mapState("auth", ["user"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/routeLogout").then(() => {
        this.$router.push("/");
      });
    },
    profile() {
      this.$router.push("/profile");
    },
  },
};
</script>
<style>
.navbar-expand-sm .navbar-nav .dropdown-menu {
  top: 63px;
  right: -47px;
  border-top-right-radius: 0px;
  position: absolute;
}

.navbar.navbar-warapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
}
.nav-link:focus-visible{
  box-shadow: none;
}
.profile-custom{
  border-bottom: 1px solid #ccc;
}

.triangle-bottom-left {
  position: absolute;
  top: -15px;
  right: 64px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 15px solid #fff;
}
</style>
